import service from './network';

export function queryCleanHouseList(data) {
    return service.request({
        url: '/area/collection/query/list',
        method: 'get',
        params: data
    })
}

export function queryDataList(data) {
    return service.request({
        url: '/area/collection/data',
        method: 'post',
        data
    })
}

export function queryDeviceList(data) {
    return service.request({
        url: '/area/collection/device',
        method: 'post',
        data
    })
}
