<template>
  <div>
    <a-row align="bottom" justify="space-between" type="flex">
      <a-col>
        <span style="font-weight: bolder; font-size: 24px; color: black">设备数量 {{ this.data.length }} 个</span>
      </a-col>
    </a-row>

    <a-row>
      <a-table :columns="columns" :data-source="data" :loading="loading" :pagination="pagination"
               @change="handleTableChange">
        <template slot="status" slot-scope="text">
          <a-badge :status="statusList[text].value" :text="statusList[text].label"/>
        </template>
      </a-table>
    </a-row>
  </div>
</template>

<script>
import {formatToDateBegin, getPreviewDate} from "@/utils/time";
import {queryVillageAnalyze} from "@/api/village";
import _ from "lodash";
import {trashType} from "@/common/config";
import {parseNumberWith2Fixed} from "@/utils/transition";
import {queryDeviceList} from "@/api/cleanHouse";

export default {
  name: "deviceList",
  props: {
    villageCode: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      query: {
        time: [],

        sort: undefined,
        order: undefined
      },
      columns: [
        {
          title: '设备编号',
          dataIndex: 'equipmentCode',
          key: 'equipmentCode',
          scopedSlots: {customRender: 'equipmentCode'},
          align: 'center'
        },
        {
          title: '设备名称',
          dataIndex: 'information.name',
          key: 'information.name',
          scopedSlots: {customRender: 'information.name'},
          align: 'center'
        },
        {
          title: '采集点',
          key: 'collectionPoint.pointName',
          dataIndex: 'collectionPoint.pointName',
          scopedSlots: {customRender: 'collectionPoint.pointName'},
          align: 'center'
        },
        {
          title: '设备类型',
          dataIndex: 'equipmentType',
          key: 'equipmentType',
          scopedSlots: {customRender: 'equipmentType'},
          align: 'center'
        },
      ],
      data: [],
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0,
        showTotal: (total, range) => `总共 ${total} 条数据`,
      },
      loading: true,
    }
  },
  mounted() {
    this.query.time = getPreviewDate(1, 1)
    this.queryDeviceList()
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      this.pagination.pageSize = pagination.pageSize
      this.pagination.current = pagination.current
      this.query.sort = sorter.field
      this.query.order = sorter.order === 'ascend' ? 'asc' : 'desc'

      this.queryDeviceList()
    },
    async queryDeviceList() {
      this.loading = true

      let data = await queryDeviceList(this.convertQuery())

      this.convertPagination(data.result)

      this.data = this.convertData(data.result.content)

      this.loading = false
    },
    customQuery() {
      this.pagination.current = 1
      this.queryVillageList()
    },
    convertQuery() {
      return {
        // startTime: formatToDateBegin(this.query.time[0]),
        // endTime: formatToDateBegin(this.query.time[1]),
        centerCode: this.$route.query.villageCode,

        pageSize: this.pagination.pageSize,
        pageNo: this.pagination.current,
        sort: this.query.sort,
        order: this.query.order,
      }
    },
    convertData(data) {
      return _.map(data, value => {
        return {
          ...value,
          key: value.id,
        }

      })
    },
    convertPagination(data) {
      this.pagination.current = data.pageNo + 1
      this.pagination.total = data.totalElements
    }
  }
}
</script>

<style scoped>

</style>
