<template>
  <div>
    <a-row align="bottom" justify="space-between" type="flex">
      <a-col>
        <span style="font-weight: bolder; font-size: 24px; color: black">采集数据</span>
      </a-col>

      <a-col>
        <a-form layout="inline">
          <a-form-item label="查询时间">
            <a-range-picker v-model="query.time" @change="customQuery"/>
          </a-form-item>
        </a-form>
      </a-col>
    </a-row>

    <!--    <a-row justify="center" type="flex">-->
    <!--      <a-col :span="6">-->
    <!--        采集总量：10000KG-->
    <!--      </a-col>-->
    <!--      <a-col :span="6">-->
    <!--        厨余总量：1220KG（20桶）-->
    <!--      </a-col>-->
    <!--      <a-col :span="6">-->
    <!--        其他垃圾总量：1220KG（20桶）-->
    <!--      </a-col>-->
    <!--    </a-row>-->

    <a-row>
      <a-table :columns="columns" :data-source="data" :loading="loading" :pagination="pagination"
               @change="handleTableChange">
      </a-table>
    </a-row>
  </div>
</template>

<script>
import {formatToDateBegin, getPreviewDate} from "@/utils/time";
import {queryDataList} from "@/api/cleanHouse";
import _ from "lodash";
import {trashType} from "@/common/config";
import {parseNumberWith2Fixed} from "@/utils/transition";

export default {
  name: "dataCollection",
  props: {
    villageCode: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      query: {
        time: [],

        sort: undefined,
        order: undefined
      },
      columns: [
        {
          title: '采集点',
          dataIndex: 'pointName',
          key: 'pointName',
          scopedSlots: {customRender: 'pointName'},
          align: 'center'
        },
        {
          title: '厨余垃圾',
          dataIndex: 'kitchenWaste',
          key: 'kitchenWaste',
          scopedSlots: {customRender: 'kitchenWaste'},
          align: 'center'
        },
        {
          title: '其他垃圾',
          dataIndex: 'other',
          key: 'other',
          scopedSlots: {customRender: 'other'},
          align: 'center'
        },
        {
          title: '可回收物',
          key: 'recyclable',
          dataIndex: 'recyclable',
          scopedSlots: {customRender: 'recyclable'},
          align: 'center'
        },
        {
          title: '有害垃圾',
          key: 'harmful',
          dataIndex: 'harmful',
          scopedSlots: {customRender: 'harmful'},
          align: 'center'
        },
      ],
      data: [],
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0,
        showTotal: (total, range) => `总共 ${total} 条数据`,
      },
      loading: true,
    }
  },
  mounted() {
    this.query.time = getPreviewDate(1, 1)
    this.queryCleanHouseWaste()
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      this.pagination.pageSize = pagination.pageSize
      this.pagination.current = pagination.current
      this.query.sort = sorter.field
      this.query.order = sorter.order === 'ascend' ? 'asc' : 'desc'

      this.queryCleanHouseWaste()
    },
    async queryCleanHouseWaste() {
      this.loading = true

      let data = await queryDataList(this.convertQuery())

      this.convertPagination(data.result)

      this.data = this.convertData(data.result.content)

      this.loading = false
    },
    customQuery() {
      this.pagination.current = 1
      this.queryCleanHouseWaste()
    },
    convertQuery() {
      return {
        startTime: formatToDateBegin(this.query.time[0]),
        endTime: formatToDateBegin(this.query.time[1]),
        centerCode: this.$route.query.villageCode,

        pageSize: this.pagination.pageSize,
        pageNo: this.pagination.current,
        sort: this.query.sort,
        order: this.query.order,
      }
    },
    convertData(data) {
      return _.map(data, value => {
        let tmp = {
          key: value.pointCode,
          ...value,
        }

        _.forEach(trashType, trash => {
          tmp[`${trash.eng}Weight`] = parseNumberWith2Fixed(value[`${trash.eng2}`])
          tmp[`${trash.eng}Rate`] = value[`${trash.eng2}Percent`]
        })

        return tmp
      })
    },
    convertPagination(data) {
      this.pagination.current = data.pageNo + 1
      this.pagination.total = data.totalElements
    }
  }
}
</script>

<style scoped>

</style>
