<template>
  <div class="content">
    <h1 style="font-weight: bolder; font-size: 24px;">小区 {{ $route.query.villageName }} - 清洁屋</h1>

    <a-row>
      <a-col :span="4">
        <a-tree :tree-data="treeData">
        </a-tree>
      </a-col>

      <a-col :span="20">
        <data-collection :villageCode="$route.query.villageCode"/>

        <device-list :villageCode="$route.query.villageCode"/>
      </a-col>
    </a-row>

  </div>
</template>

<script>
import DataCollection from './components/dataCollection'
import DeviceList from "./components/deviceList";
import {queryCleanHouseList, queryDeviceList} from "@/api/cleanHouse";
import _ from "lodash";

export default {
  name: "cleanHouseList",
  components: {DeviceList, DataCollection},
  data() {
    return {
      treeData: [
        {
          title: '清洁屋列表',
          key: '清洁屋列表',
          children: [],
        },
      ],
      data: []
    }
  },
  mounted() {
    this.queryDeviceList()
  },
  methods: {
    async queryDeviceList() {
      let data = await queryCleanHouseList({
        centerCode: this.$route.query.villageCode
      })

      this.data = this.convertData(data.result)
      this.treeData[0].children = _.map(this.data, value => {
        return {
          title: value.information.name,
          key: value.information.name
        }
      })
    },
    convertData(data) {
      return _.map(data, value => {
        return {
          ...value,
          key: value.id,
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.content {
  background-color: white;
  padding: 15px;
}
</style>
